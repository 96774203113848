function mapInitBig() {
    ymaps.ready(init1);
    function init1 () {
        // Создание экземпляра карты и его привязка к контейнеру с
        // заданным id ("map")
        var myMap = new ymaps.Map('geographyMap', {
                // При инициализации карты, обязательно нужно указать
                // ее центр и коэффициент масштабирования
                center: [60.939845, 84.874004],
                zoom: 3,
                controls: []
                //type:'yandex#hybrid'
            }),
            //Добавляем элементы управления
            /* myMap.controls
             // Кнопка изменения масштаба
                 .add('zoomControl')
                 // Список типов карты
                 .add('typeSelector', { right: 30, top: 5 })*/
            // Создание метки
            myPlacemark1 = new ymaps.Placemark(
                // Координаты метки
                [55.755814, 37.617635],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Москва',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark2 = new ymaps.Placemark(
                // Координаты метки
                [56.838011, 60.597465],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Екатеринбург',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark3 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark4 = new ymaps.Placemark(
                // Координаты метки
                [52.287054, 104.281047],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Иркутск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                })/*,
            myPlacemark5 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark6 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark7 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark8 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark9 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                }),
            myPlacemark10 = new ymaps.Placemark(
                // Координаты метки
                [55.030199, 82.920430],{  balloonContentBody: [
                        '<address>',
                        'г.&nbsp;Новосибирск',
                        '</address>'
                    ].join('')
                }, {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map_icon.png',
                    iconImageSize: [30, 50],
                    iconImageOffset: [-18, -45]
                })*/;
        // Добавление метки на карту
        myMap.behaviors.disable('scrollZoom');
        myMap.controls.add('zoomControl');
        myMap.geoObjects
            .add(myPlacemark1)
            .add(myPlacemark2)
            .add(myPlacemark3)
            .add(myPlacemark4)
            /*.add(myPlacemark5)
            .add(myPlacemark6)
            .add(myPlacemark7)
            .add(myPlacemark8)
            .add(myPlacemark9)
            .add(myPlacemark10)*/;
    }
}