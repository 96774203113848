/*//=require components/example.js*/

//=require ../components/**.*

//=require ../../common.blocks/Accordion/index.js
//=require ../../project.blocks/MyDefmenu/Nav/index.js

$(document).ready(function(){

    var phonemaskOk = 0;

    $(".MaskedPhone").inputmask("8 (999) 999-99-99",{
        "oncomplete": function(){ phonemaskOk = 1; },
        "onincomplete": function(){ phonemaskOk = 0; },
        "oncleared": function(){ phonemaskOk = 0; }
    });


    $(".FancyBtn").fancybox({
        maxWidth : 700,
        padding: 0
    });

    $(".FancyMedia").fancybox({
        padding: 0
    });

    $(".ProcessingPersonalDataText-Link, .ImPolitic").fancybox({
        maxWidth : 700
    });

    $('.FeedbackSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        mobileFirst: true,
        prevArrow: '<div class="Slider-Arrow Slider-Arrow_prev">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        nextArrow: '<div class="Slider-Arrow Slider-Arrow_next">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.LicenseSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        mobileFirst: true,
        prevArrow: '<div class="Slider-Arrow Slider-Arrow_prev">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        nextArrow: '<div class="Slider-Arrow Slider-Arrow_next">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.PhotogalSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        mobileFirst: true,
        prevArrow: '<div class="Slider-Arrow Slider-Arrow_prev">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        nextArrow: '<div class="Slider-Arrow Slider-Arrow_next">\n' +
        '                        <svg class="Icon">\n' +
        '                            <use\n' +
        '                                    xlink:href="img/sprite.svg#arrow"\n' +
        '                                    href="img/sprite.svg#arrow"></use>\n' +
        '                        </svg>\n' +
        '                    </div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.FormSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        prevArrow: '',
        nextArrow: '<button type="button" class="FormSlider-Arrow FormSlider-Next Btn">' +
        'Продолжить <img src="/img/SliderArrow.png" alt="">' +
        '</button>'
    });

    var myDefMenu = new DefMenu({
        btn : ".DefmenuBtn",
        menu : ".Defmenu"
        //build : true,
        //links: "auto"
    });

    function animatedAnchorScroll(self) {
        var target = $(self).attr('href');
        $('html, body').animate({ scrollTop: $(target).offset().top-80}, 500);
    }
    $('a[href^="#"]').click(function () {
        animatedAnchorScroll(this);
        myDefMenu.close();
        return false;
    });

    validateForm({
        formId: 'form-intro'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-contact'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-calc'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-test'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });

    var center = [54.773377, 56.062644];
    var iconCoord = [54.773377, 56.062644];
    mapInit(center, iconCoord);

    mapInitBig();

});