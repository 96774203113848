(function () {
    var navDropItemCollection = document.querySelectorAll('.HeaderNav-Item[data-drop]');

    //console.log(navDropItemCollection.length);

    function headerNavHandler() {
        this.classList.toggle('HeaderNav-Item_active');
    }

    function headerNavHandlerClose() {
        navDropItemCollection.forEach(function (item) {
            item.classList.remove('HeaderNav-Item_active');
        });
    }

    if (navDropItemCollection.length) {
        navDropItemCollection.forEach(function (item) {
            item.addEventListener('click', headerNavHandler);
        });
        document.addEventListener('click', function (evt) {
            var target = evt.target;
            var container = document.querySelector('body');

            while(target != this){
                var current;

                navDropItemCollection.forEach(function (item) {
                    if (target == item){
                        current = item;
                    }
                });

                if (target == container){
                    headerNavHandlerClose();
                    return;
                }

                if (target == current){
                    return;
                }
                target = target.parentNode;
            }
        });
    }
})();