(function () {
    var accordionCollection = document.querySelectorAll('.MyDefmenuNav-Link_js_accordion');

    function accordionToggleHandler() {
        this.classList.toggle('active');
        this.parentElement.querySelector('.MyDefmenuNav-List_lvl2').classList.toggle('active');
    }

    if(accordionCollection.length) {
        accordionCollection.forEach(function (item) {
            item.addEventListener('click', accordionToggleHandler)
        });
        //console.log();
    }
})();